<template>

    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>

        <v-expansion-panels hover>
            <v-expansion-panel>
                <v-expansion-panel-header>Filter</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-container fluid>
                        <v-row align="start">
                            <v-col class="float-right" cols="12" sm="4">
                                <v-text-field label="Seartch input" v-model="serverParams.search"
                                              @keyup="searchInput"></v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="4">
                                <v-select :items="filter"
                                          label="Standard"></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <vue-good-table mode="remote"
                        @on-page-change="onPageChange"
                        @on-sort-change="onSortChange"
                        @on-column-filter="onColumnFilter"
                        @on-per-page-change="onPerPageChange"
                        :totalRows="totalRecords"
                        :isLoading.sync="isLoading"
                        :pagination-options="{ enabled: true, }"
                        :rows="rows"
                        :columns="columns"
                        theme="black-rhino">
            <!--            added action column in vue good table-->
            <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'actions'">
            <v-tooltip left>
                 <template v-slot:activator="{ on }">
                     <router-link :to="'/user/list/'+1" class="view"><i class="material-icons eye" v-on="on">remove_red_eye</i></router-link>
                 </template>
                <span>User Info</span>

            </v-tooltip>
        </span>
                <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
            </template>
        </vue-good-table>
    </v-content>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'List',
        watch: {
            dialog(val) {
                val || this.close()
            },
        },
        components: {},
        data() {
            return {
                isLoading: false,
                items: [
                    {
                        text: 'Dashboard',
                        disabled: false,
                        to: '/',
                        exact: true
                    },
                    {
                        text: 'Division',
                        disabled: false,
                        to: '/division/' + 1,
                        exact: true
                    },
                    {
                        text: 'Branch',
                        disabled: false,
                        to: '/branch/list/' + 1,
                        exact: true
                    },
                    {
                        text: 'Sub Branch',
                        disabled: true,
                        to: '/sub-branch/list/' + 1,
                        exact: true
                    }
                ],
                filter: ['Foo', 'Bar', 'Fizz', 'Buzz'],
                columns: [
                    {
                        label: 'id',
                        field: 'branchID'
                    },
                    {
                        label: 'Branch Name',
                        field: 'branchName'
                    },
                    {
                        label: 'Branch Address',
                        field: 'branchAddress'
                    },
                    {
                        label: 'Branch Phone Number',
                        field: 'branchPhoneNumber'
                    },
                    {
                        label: 'Actions',
                        field: 'actions',
                        sortable: false,
                    }
                ],
                rows: [],
                totalRecords: 0,
                serverParams: {
                    search: '',
                    columnFilters: {},
                    sort: [{
                        field: 'branchID',
                        type: 'asc',
                    }],
                    page: 1,
                    perPage: 10
                },
            };
        },
        mounted() {
            this.loadItems()
        },
        methods: {
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            searchInput() {
                this.loadItems();
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            // load items is what brings back the rows from server
            async loadItems() {
                // eslint-disable-next-line no-console
                if (this.totalRecords < this.serverParams.perPage) {
                    this.serverParams.perPage = 10
                    this.serverParams.page = 1
                }
                let param = {
                    "PageSize": this.serverParams.perPage,
                    "PageNo": this.serverParams.page,
                    "OrderType": this.serverParams.sort[0].type,
                    "OrderBy": this.serverParams.sort[0].field,
                    "Name": this.serverParams.search
                }

                // eslint-disable-next-line no-console
                console.log(this.serverParams.sort) // eslint-disable-next-line no-console
                console.log(this.serverParams.sort[0].type)

                let list = await axios.post('Branch/BranchList', param)
                this.rows = list.data.data
                this.totalRecords = list.data.totalCount

                return
            }
        }
    };
</script>

<style lang="scss" scoped>

    .v-item-group {
        background: #d2e3ff73
    }

    .v-expansion-panel-header {
        background: #d2e3ff73
    }

    .eye {
        padding: 0.5em;
    }

    .view {
        text-decoration: none;
    }
</style>
